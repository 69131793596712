<template>
  <v-app id="dashboard-view">
    <core-toolbar style="heigth: 50px; padding: 0px 0px 0px" app />

    <core-drawer app />

    <v-dialog v-model="exibeModalFeedback" max-width="600px" persistent>
      <v-card width="600px">
        <v-toolbar color="primary"
          ><v-tooblar-title style="color: white">{{
            perguntaFeedback.titulo
          }}</v-tooblar-title>
        </v-toolbar>

        <v-card-text>
          <v-row
            no-gutters
            class="ma-1"
            v-if="perguntaFeedback.curtir_nao_curtir !== true"
          >
            <v-col cols="12" sm="12" style="margin-top: 10px">
              <p style="font-size: 20px; font-weight: 500">
                {{ perguntaFeedback.pergunta }}
              </p>
            </v-col>
            <v-col cols="12" sm="12" style="margin-top: 10px">
              <input
                type="number"
                v-model="respostaFeedback"
                style="width: 100%; border: 1px solid black; padding: 10px"
                placeholder="Digite sua resposta aqui"
              />
            </v-col>
            <v-col cols="12" sm="12">
              <v-row>
                <v-col cols="12" sm="8"></v-col>
                <v-col cols="12" sm="4">
                  <v-btn
                    elevation=""
                    color="success"
                    large
                    style="width: 100%"
                    @click="responderFeedback(perguntaFeedback)"
                    >Responder</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row no-gutters class="ma-1" v-else>
            <v-col cols="12" sm="12" style="margin-top: 10px">
              <p style="font-size: 20px; font-weight: 500">
                {{ perguntaFeedback.pergunta }}
              </p>
            </v-col>
            <v-col cols="12" sm="12">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-btn
                    elevation=""
                    color="error"
                    large
                    style="width: 100%"
                    @click="responderFeedbackDeslike(perguntaFeedback)"
                  >
                    <v-icon
                      color="white"
                      style="margin-right: 10px; margin-top: 5px"
                      >mdi-thumb-down</v-icon
                    >
                    Não Curti
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-btn
                    elevation=""
                    color="success"
                    large
                    style="width: 100%"
                    @click="responderFeedbackLike(perguntaFeedback)"
                  >
                    <v-icon
                      color="white"
                      style="margin-right: 10px; margin-top: 5px"
                      >mdi-thumb-up</v-icon
                    >
                    Curtir
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <div class="ma-1"></div>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>

    <v-main>
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </v-main>
  </v-app>
</template>

<script>
import coreDrawer from "../components/core/Drawer";
import coreToolbar from "../components/core/Toolbar";

export default {
  name: "DashboardView",
  components: {
    coreDrawer,
    coreToolbar,
  },
  data: () => ({
    exibeModalFeedback: false,
    perguntaFeedback: [],
    respostaFeedback: null,
    idclientebuild: null,
  }),
  methods: {
    async getFeedBack() {
      try {
        await this.$http
          .post("/ambiente/getDadosAmbiente")
          .then((resp) => {
            console.log("resp.data ", resp.data);
            this.idclientebuild = resp.data.BUILD_ID_CLIENTE;
          })
          .catch((error) => alert(error));

        // const tipo = localStorage.getItem('tipo');

        // const master = tipo && Number(tipo) === 1 ? true : false;

        const master = true;

        this.$http
          .post(
            "https://apies.economysoftware.com.br/feedback/buscarFeedBackPendentes",
            {
              id_cliente: this.idclientebuild,
              id_usuario: localStorage.getItem("userid"),
              id_sistema: 1,
              master,
            }
          )
          .then((resp) => {
            if (resp.data?.length > 0) {
              this.perguntaFeedback = resp.data[0];
              this.exibeModalFeedback = true;
            } else {
              this.exibeModalFeedback = false;
              this.perguntaFeedback = [];
            }
          })
          .catch(() => {
            this.exibeModalFeedback = false;
            this.perguntaFeedback = [];
          });
      } catch (error) {
        this.exibeModalFeedback = false;
        this.perguntaFeedback = [];
      }
    },
    responderFeedback(dadospergunta) {
      try {
        if (this.respostaFeedback?.length) {
          console.log("respondendo para cliente ", this.idclientebuild);
          this.$http
            .post(
              "https://apies.economysoftware.com.br/feedback/responderFeedback",
              {
                id_cliente: this.idclientebuild,
                id_feedback: dadospergunta.id,
                resposta: this.respostaFeedback,
                id_usuario: localStorage.getItem("userid"),
                nome_usuario: localStorage.getItem("apelido"),
              }
            )
            .then(() => {
              this.exibeModalFeedback = false;
              this.respostaFeedback = null;
              this.getFeedBack();
            })
            .catch(() => {
              this.exibeModalFeedback = false;
              this.perguntaFeedback = [];
              this.respostaFeedback = null;
            });
        } else {
          alert("Que tal informar uma Resposta um poquinho maior?");
        }
      } catch (error) {
        this.exibeModalFeedback = false;
        this.perguntaFeedback = [];
      }
    },
    responderFeedbackDeslike(dadospergunta) {
      try {
        console.log("respondendo para cliente deslike ", this.idclientebuild);

        this.$http
          .post(
            "https://apies.economysoftware.com.br/feedback/responderFeedback",
            {
              id_cliente: this.idclientebuild,
              id_feedback: dadospergunta.id,
              resposta: "Não Curti",
              id_usuario: localStorage.getItem("userid"),
              nome_usuario: localStorage.getItem("apelido"),
            }
          )
          .then(() => {
            this.exibeModalFeedback = false;
            this.respostaFeedback = null;
            this.getFeedBack();
          })
          .catch(() => {
            this.exibeModalFeedback = false;
            this.perguntaFeedback = [];
            this.respostaFeedback = null;
          });
      } catch (error) {
        console.log(error);
        this.exibeModalFeedback = false;
        this.perguntaFeedback = [];
      }
    },
    responderFeedbackLike(dadospergunta) {
      try {
        console.log("respondendo para cliente like ", this.idclientebuild);
        this.$http
          .post(
            "https://apies.economysoftware.com.br/feedback/responderFeedback",
            {
              id_cliente: this.idclientebuild,
              id_feedback: dadospergunta.id,
              resposta: "Curti",
              id_usuario: localStorage.getItem("userid"),
              nome_usuario: localStorage.getItem("apelido"),
            }
          )
          .then(() => {
            this.exibeModalFeedback = false;
            this.respostaFeedback = null;
            this.getFeedBack();
          })
          .catch(() => {
            this.exibeModalFeedback = false;
            this.perguntaFeedback = [];
            this.respostaFeedback = null;
          });
      } catch (error) {
        this.exibeModalFeedback = false;
        this.perguntaFeedback = [];
      }
    },
  },
  metaInfo() {
    return {
      title: "Ficha Digital",
    };
  },
  mounted() {
    try {
      this.getFeedBack();
    } catch (error) {
      console.log(error);
    }
  },
};
</script>

<style></style>
